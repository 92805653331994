<template>
  <!-- 债权转让登记 -->
  <div class="page-card-demo  page-info-content">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getDate"
      :form-inline="formInline"
      :form-item-arr="formItemArr"
    />
    <div>
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      >
        <template #echainState="{row}">
          {{ $codeNew(echainState,row.echainState + '') }}
        </template>
      </Table>
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getDate" />
    </div>
  </div>
</template>

<script>
import { getEchainTransferList } from '@/api/tradeFinancing.js'
import FormSearch from '@/components/FormSearch'
import Pagination from '@/components/Pagination2'
import Table from '@/components/Table.vue'
export default {
  components: { FormSearch, Pagination, Table },
  data() {
    return {
      formInline: {

      },
      formItemArr: [
        { type: 'input', label: '信链编号', value: 'echainNo', width: '120px' },
        { type: 'input', label: '开立方', value: 'openCmpName', width: '80px' },
        { type: 'input', label: '原始持有方', value: 'recvCmpName', width: '80px' },
        { type: 'select', label: '信链状态', value: 'echainState', pLabel: 'dictName', pValue: 'dictId', child: this.$store.getters.getDictionaryItem('ECHAIN_STATE'), width: '80px' }
      ],
      itemData: [
        { label: '信链编号', prop: 'echainNo', width: 120 },
        { label: '开立方', prop: 'openCmpName', width: 280 },
        { label: '原始持有方', prop: 'recvCmpName', width: 180 },
        { label: '信链开立日期', prop: 'echainDate', width: 180 },
        { label: '信链到期日期', prop: 'echainValidate', width: 180 },
        { label: '信链流转次数', prop: 'transferNum', width: 180 },
        { label: '信链登记次数', prop: 'checkNum', width: 180 },
        { label: '未登记次数', prop: 'unloginNum', width: 180 },
        { label: '信链状态', prop: 'echainState', width: 180 }
      ],
      operationButton: [
        { bType: 'primary', label: '查看详情', handleEvent: this.viewDetails }
      ],
      listData: [],
      total: 0,
      loading: false,
      dateRange: [],
      echainState: []
    }
  },
  created() {
    this.$store.getters.getDictionaryItem('ECHAIN_STATE').map(item => {
      if (item.dictId === '4' || item.dictId === '05' || item.dictId === '06') {
        this.echainState.push(item)
      }
    })
  },
  methods: {
    getDate(params) {
      this.loading = true
      this.formInline = {
        ...this.formInline,
        ...params
      }
      getEchainTransferList(this.formInline, res => {
        this.loading = false
        this.listData = res.data.list.map(item => {
          item.unloginNum = Math.max(0, item.transferNum * 1 - item.checkNum * 1)
          if (item.paymentLetterId) {
            item.openAggreement = true
          }
          if (item.voucherId) {
            item.certificate = true
          }
          if (item.echainAgreeFileId) {
            item.transferAggreement = true
          }
          if (item.echainFlowFileId) {
            item.transferOrder = true
          }
          return item
        })
        this.total = res.data.total
      }, () => {
        this.loading = false
      })
    },
    viewDetails({ id }) {
      this.$router.push({
        path: '/echainManage/echainEnrollmentDetail',
        query: { id }
      })
    }
  }
}
</script>

<style>

</style>
